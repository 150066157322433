import { toast } from "react-toastify";
import { apiSlice } from ".";
import { errorToast } from "../../constants/toast";

export const propertiesApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAgencyProperties: builder.query({
      query: ({ agencyId, agentId }) =>
        `/PropertyTables/GetPropByAgencyidAndPropstatus?agcid=${agencyId}`,
      providesTags: ["Properties"],

      async onQueryStarted(args, { queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (err) {
          errorToast("Error Fetching Properties, Please Try Again Later");
        }
      },

      transformResponse: (res, _, { agencyId, agentId }) => {
        return res.map((item) => {
          return {
            ...item,
            bedrooms: item.bedrooms === -1 ? "Studio" : item.bedrooms,
            ownfirstname: item.agentid === agentId ? item.ownfirstname : null,
            mobileno: item.agentid === agentId ? item.mobileno : null,
            restricted: item.agentid === agentId ? null : true,
          };
        });
      },
    }),

    addProperty: builder.mutation({
      query: (data) => ({
        url: `/FileUploader/PropertyAdding?agcid=${data.agencyid}`,
        method: "POST",
        body: data,
        formData: true,
      }),
      // invalidatesTags: ["Properties"],

      async onQueryStarted(args, { queryFulfilled }) {
        toast.promise(queryFulfilled, {
          pending: "Adding Property",
          success: "Property Added",
          error: "Error Adding Property",
        });
      },
    }),

    editProperty: builder.mutation({
      query: (data) => ({
        url: `/FileUploader/PropertyInfoAfterEditing/${data.propertyid}`,
        method: "POST",
        body: data,
      }),
      // invalidatesTags: ["Properties"],

      async onQueryStarted(args, { queryFulfilled }) {
        toast.promise(queryFulfilled, {
          pending: "Editing Property",
          success: "Property Edited",
          error: "Error Editing Property",
        });
      },
    }),

    getPropertyById: builder.query({
      query: (propertyId) => `/PropertyTables/GetPropertyTable/${propertyId}`,
    }),

    getAddedProperty: builder.query({
      query: (propertyId) => `/PropertyFilter/Gettt?propid=${propertyId}`,
    }),

    getPropertyImages: builder.query({
      query: (propertyId) => `/PropertyImagesTables/GetImageByPropertyID/${propertyId}`,
      providesTags: ["PropertyImages"],
    }),

    addImages: builder.mutation({
      query: (data) => ({
        url: `/FileUploader/uploadfilesByID2?location=${data.watermark.location}&opacity=${data.watermark.opacity}&logosize=${data.watermark.size}&logoFileName=${data.watermark.name}`,
        method: "POST",
        body: data.formData,
      }),
      async onQueryStarted(args, { queryFulfilled }) {
        toast.promise(queryFulfilled, {
          pending: "Uploading Images",
          success: "Images Uploaded",
          error: "Error Uploading Images",
        });
      },
    }),
  }),
});

export const {
  useGetAgencyPropertiesQuery,
  useAddPropertyMutation,
  useGetPropertyByIdQuery,
  useGetPropertyImagesQuery,
  useEditPropertyMutation,
  useAddImagesMutation,
  useLazyGetAddedPropertyQuery,
} = propertiesApi;
