import { toast } from "react-toastify";
import { apiSlice } from ".";
import { logout, setAgent } from "../features/authSlice";

export const authApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    agentLogin: builder.mutation({
      query: (credentials) => ({
        url: "/agent/agentLogin",
        method: "POST",
        body: credentials,
      }),
    }),

    getAgent: builder.query({
      query: (agentId) => `/Agent/GetAgentTable/${agentId}`,
      providesTags: ["Agent"],
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data: agent } = await queryFulfilled;
          if (agent && agent.length === 0) {
            dispatch(logout())
          }
          dispatch(setAgent(agent[0]));

        } catch {
          dispatch(logout())
        }
      },
    }),

    editAgent: builder.mutation({
      query: (data) => ({
        url: `/Agent/PutAgentTable/${data.agentid}`,
        body: data,
        method: "POST",
      }),
      invalidatesTags: ["Agent"],

      async onQueryStarted(ars, { dispatch, queryFulfilled }) {
        toast.promise(queryFulfilled, {
          pending: "Upadting Profile",
          success: "Profile Updated",
          error: "Error Updating Profile",
        });
      },
    }),


    getAgentDashboard: builder.query({
      query: (data) => ({
        url: `/Dashboard/AgentDashboard?agtid=${data.agentId}&agcid=${data.agencyId}`,
      }),
    }),
  }),
});

export const {
  useAgentLoginMutation,
  useGetAgentQuery,
  useEditAgentMutation,
  useGetAgentDashboardQuery,
  useLazyGetAgentQuery
} = authApi;
