import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { Provider as ReduxProvider } from "react-redux";
import { router } from "./Router/router";
import { RouterProvider } from "react-router-dom";
import store from "./redux/store";
import { ThemeProvider } from "@mui/material";
import { baseTheme } from "./assets/global/Theme-variable";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./globalStyles.css"

const Provider = ({ children }) => {
  const offlineToast = React.useRef(null);
  const onlineToast = React.useRef(null);

  useEffect(() => {
    function onlineHandler() {
      onlineToast.current = toast.success("You are Online", {
        closeOnClick: false,
        autoClose: false,
      })
      toast.dismiss(offlineToast.current);
    }

    function offlineHandler() {
      offlineToast.current = toast.error("You are Offline", {
        closeOnClick: false,
        autoClose: false,
      });
      toast.dismiss(onlineToast.current);
    }

    window.addEventListener("online", onlineHandler);
    window.addEventListener("offline", offlineHandler);


    return () => {
      window.removeEventListener("online", onlineHandler);
      window.removeEventListener("offline", offlineHandler);
    };
  }, []);
  return <ReduxProvider store={store}>{children}</ReduxProvider>;
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider >
      <ThemeProvider theme={baseTheme}>
        <ToastContainer newestOnTop autoClose={2000} position="top-center" />
        <RouterProvider router={router} />
      </ThemeProvider>
    </Provider>
  </React.StrictMode>
);

reportWebVitals();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
