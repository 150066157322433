import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { DatePicker, TimePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Grid from "@mui/material/Grid";
import { TRANSACTION_TYPES } from "../../../constants/transaction";
import { toast } from "react-toastify";
import {
  useGetTransactionDetailsQuery,
  usePostTransactionMutation,
} from "../../../redux/api/DealsPdf";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectCurrentAgent } from "../../../redux/features/authSlice";
import { combineDateAndTime } from "../../../constants/customFunction";
import DateAndTime from "../../../components/DateAndTime";
import { FormControl, InputLabel, Select } from "@mui/material";
import styled from 'styled-components';

export const AddTransaction = () => {
  const currentAgent = useSelector(selectCurrentAgent);
  const agtid = currentAgent?.agentid;
  const methods = useForm();
  const navigate = useNavigate();
  const location = useLocation();
  const pathParts = location.pathname.split("/");
  const dealId = pathParts[4];
  const transactionId = pathParts[5];
  const token = localStorage.getItem("token");
  const [amount, setAmount] = useState("");
  const [remainingAmount, setRemainingAmount] = useState(0);
  const [startDate, setStartDate] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [paymentType, setPaymentType] = useState("");

  const { data: TransactionDetails } = useGetTransactionDetailsQuery({
    agtid: agtid,
    dealId: dealId,
    token: token,
  });

  let lastTransaction;
  if (TransactionDetails && TransactionDetails.length > 0) {
    lastTransaction = TransactionDetails[TransactionDetails.length - 1];
  }

  const [postTransaction, { isLoading, isError, error }] =
    usePostTransactionMutation();

  const onSubmit = (data) => {
    if (!startDate || !startTime) {
      toast.error("Date and Time are required");
      return;
    }

    const dateAndTime = combineDateAndTime(startDate, startTime);

    const submitData = {
      amountReceived: amount,
      paymentType: paymentType,
      amountReceivedDate: dateAndTime,
      dealid: dealId,
    };

    postTransaction({
      data: submitData,
      token: token,
      agtid: agtid,
    })
      .unwrap()
      .then(() => {
        navigate("/accounts/deals");
      })
      .catch((err) => {
        console.error("Failed to post transaction:", err);
        toast.error(
          "Failed to post transaction: " + err.data?.Message || "Unknown error"
        );
      });
  };

  useEffect(() => {
    if (lastTransaction) {
      const totalAmount = lastTransaction.balance || 0;
      setRemainingAmount(totalAmount - amount);
    }
  }, [amount, lastTransaction]);

  return (
    <>
      <h1 className="mb-5 text-3xl">New Transaction</h1>

      <StyledInputsContainer>
        <DateAndTime
          startDate={startDate}
          setStartDate={setStartDate}
          startTime={startTime}
          setStartTime={setStartTime}
        />

        <FormControl fullWidth variant="outlined" size="small">
          <InputLabel>Payment Type</InputLabel>
          <Select
            value={paymentType}
            onChange={(e) => setPaymentType(Number(e.target.value))}
            label="Payment Type"
          >
            {TRANSACTION_TYPES.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <TextField
          label="Amount"
          type="number"
          fullWidth
          size="small"
          variant="outlined"
          value={amount}
          onChange={(e) => setAmount(Number(e.target.value))}
        />

        <TextField
          label="Will be remaining"
          type="number"
          fullWidth
          size="small"
          variant="outlined"
          value={remainingAmount}
          InputProps={{
            readOnly: true,
          }}
        />
      </StyledInputsContainer>

      {TransactionDetails && TransactionDetails.length > 0 && (
        <div className="flex text-gray-500 my-5 items-center justify-between sm:w-[50%]">
          <div>
            <p className="p-2">Total Amount</p>
            <p className="p-2">Remaining Amount</p>
          </div>
          <div>
            <p className="p-2">
              {lastTransaction && lastTransaction.amountTotal}
            </p>
            <p className="p-2">
              {lastTransaction && (lastTransaction.balance ?? 0)}
            </p>
          </div>
        </div>
      )}

      <button
        type="submit"
        className="bg-green hover:bg-green-700 text-white py-2 px-4 rounded mx-auto"
        onClick={methods.handleSubmit(onSubmit)}
      >
        Submit
      </button>
    </>
  );
};

const StyledInputsContainer = styled.div({
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
    gap: "24px",
    marginTop: "24px",
    "@media (max-width: 1024px)": { // roughly lg
      gridTemplateColumns: "1fr 1fr 1fr",
    },
    "@media (max-width: 768px)": { // roughly md
      gridTemplateColumns: "1fr 1fr",
    },
    "@media (max-width: 480px)": { // roughly sm
      gridTemplateColumns: "1fr",
    },
  });
  
