import React, { useEffect } from "react";
import { useLazyGetAgentQuery } from "../redux/api/authApi";
import { useNavigate } from "react-router-dom";
import { Box, CircularProgress } from "@mui/material";
import { useSelector } from "react-redux";
import { selectCurrentAgent } from "../redux/features/authSlice";

export default function RequireAuth({ children }) {
  const agent = useSelector(selectCurrentAgent);
  const agentId = useSelector((state) => state.auth.agentId);

  const [getAgent, { data, isLoading, error, isSuccess }] = useLazyGetAgentQuery();

  const navigate = useNavigate();

  useEffect(() => {
    if (agentId) {
      getAgent(agentId);
    } else {
      navigate("/login");
    }
  }, [agentId]);

  if (isLoading || !agent) {
    return (
      <Box sx={{ width: "100%", height: "100vh", display: "grid", placeItems: "center" }}>
        <CircularProgress sx={{ margin: "auto" }} />
      </Box>
    );
  }

  return children;
}
