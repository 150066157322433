import { createSlice } from "@reduxjs/toolkit";
import { PAKISTAN_ID, UAE_ID } from "../../constants/countries";

const getUserCountry = () => {
  try {
    return JSON.parse(localStorage.getItem("country"));
  } catch (err) {
    return null;
  }
};
const countrySlice = createSlice({
  name: "country",
  initialState: {
    countries: [
      { id: UAE_ID, name: "UAE", image: "/static/images/flags/UAE.png", currency: "AED" },
      {
        id: PAKISTAN_ID,
        name: "Pakistan",
        image: "/static/images/flags/Pakistan.png",
        currency: "PKR",
      },
    ],
    activeCountry: getUserCountry() || {
      id: UAE_ID,
      name: "UAE",
      image: "/images/flags/UAE.png",
      currency: "AED",
    },
  },

  reducers: {
    updateActiveCountry: (state, action) => {
      state.activeCountry = action.payload;
      localStorage.setItem("country", JSON.stringify(action.payload));
    },
  },
});

export const { updateActiveCountry } = countrySlice.actions;

export default countrySlice.reducer;

export const selectCountries = (state) => state.country.countries;
export const selectActiveCountry = (state) => state.country.activeCountry;
