import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import AssignmentTurnedInOutlinedIcon from "@mui/icons-material/AssignmentTurnedInOutlined";
import { ACCOUNT_DEALS_ROUTE, ADD_PROPERTY_ROUTE, CONTACTS_ROUTE, LEADS_ROUTE, PROPERTY_ROUTE } from "../../../constants/routes";
import { MdOutlinePictureAsPdf } from "react-icons/md";

export const LINKS = [
  {
    title: "Dashboard",
    icon: DashboardOutlinedIcon,
    href: "/",
  },
  {
    title: "Properties",
    icon: AssignmentTurnedInOutlinedIcon,
    href: PROPERTY_ROUTE,
  },

  {
    title: "Add Property",
    icon: AssignmentTurnedInOutlinedIcon,
    href: ADD_PROPERTY_ROUTE,
  },

  {
    title: "Leads",
    icon: AssignmentTurnedInOutlinedIcon,
    href: LEADS_ROUTE,
  },
  {
    title: "Contacts",
    icon: AssignmentTurnedInOutlinedIcon,
    href: CONTACTS_ROUTE,
  },
];


export const ACCOUNTS_OPTIONS = [
  {
    title: "Deals",
    icon: MdOutlinePictureAsPdf,
    href: ACCOUNT_DEALS_ROUTE,
  },
  // {
  //   title: "Total Income",
  //   icon: IoMdArrowDropright,
  //   href: ACCOUNT_INCOME_ROUTE ,
  // },
];
