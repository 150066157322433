import { createBrowserRouter } from "react-router-dom";
import FullLayout from "../layouts/FullLayout/FullLayout";
import Login from "../pages/Login";
import {
  ADD_PROPERTY_ROUTE,
  EDIT_PROPERTY_ROUTE,
  LEADS_ROUTE,
  PROFILE_ROUTE,
  PROPERTY_ROUTE,
  ADD_LEAD_ROUTE,
  EDIT_LEAD_ROUTE,
  VIEW_ROUTE,
  APPOINTMENT_ROUTE,
  VIEWING_DONE_ROUTE,
  DEAL_DEAL_ROUTE,
  VIEW_DETAILS_ROUTE,
  HISTORY_ROUTE,
  CONTACTS_ROUTE,
  ACCOUNT_DEALS_ROUTE,
  ACCOUNT_ADD_TRANSACTION_ROUTE,
  TRANSACTION_DETAILS_ROUTE,
} from "../constants/routes";
import { Suspense, lazy } from "react";
import RequireAuth from "../components/RequireAuth";
import { Box, CircularProgress } from "@mui/material";
import { selectCurrentAgent } from "../redux/features/authSlice";
import { useSelector } from "react-redux";
import { useGetAgencyPropertiesQuery } from "../redux/api/propertiesApi";
import { PropertiesData } from "../hooks/useContext/agencyProperties";
import { LeadsData } from "../hooks/useContext/leadsData";
import { useGetLeadsQuery } from "../redux/api/leadsApi";
import Contacts from "../pages/Contacts";
import { DealsPdf } from "../pages/Accounts/deals/Deals";
import { DealsDetails } from "../pages/Accounts/deals/DealsDetails";
import { AddTransaction } from "../pages/Accounts/deals/AddTransaction";
import { TransactionDetails } from "../pages/Accounts/deals/TransactionDetails";

const Dashboard = lazy(() => import("../pages/Dashboard"));
const Properties = lazy(() => import("../pages/Properties"));
const AddProperty = lazy(() => import("../pages/Properties/AddProperty"));
const EditProperty = lazy(() => import("../pages/Properties/EditProperty"));
const Profile = lazy(() => import("../pages/Profile"));
const Leads = lazy(() => import("../pages/Leads"));
const AddLead = lazy(() => import("../pages/Leads/AddLead"));
const EditLead = lazy(() => import("../pages/Leads/EditLead"));
const ViewLead = lazy(() => import("../pages/Leads/ViewLead"));
const ViewingArranged = lazy(() => import("../pages/Leads/status/ViewingArranged"));
const Deal = lazy(() => import("../pages/Leads/status/deal/Deal"));
const ViewingDone = lazy(() => import("../pages/Leads/status/ViewingDone"));
const LeadDetails = lazy(() => import("../pages/Leads/LeadDetails"));
const History = lazy(() => import("../pages/Leads/History"));



const SuspenseLayout = ({ children }) => {

  const DataProvider = ({ children }) => {
    const currentAgent = useSelector(selectCurrentAgent);
    const { data: properties = [], isLoading } = useGetAgencyPropertiesQuery({
      agencyId: currentAgent.agencyid,
      agentId: currentAgent.agentid,
    });

    // const { data: agencyLeads = [], isLoading: LeadsLoading, refetch  } = useGetLeadsQuery(
    //   currentAgent.agencyid
    // );
    const { data: agencyLeads = [], isLoading: LeadsLoading, refetch  } = useGetLeadsQuery(currentAgent.agencyid);

    return (
      <PropertiesData.Provider value={{ properties, isLoading }}>
        <LeadsData.Provider value={{ agencyLeads, LeadsLoading, refetch }}>
          {children}
        </LeadsData.Provider>
      </PropertiesData.Provider>
    );
  };
  return (
    <Suspense
      fallback={
        <Box
          sx={{
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      }
    >
      <DataProvider>
        {children}
      </DataProvider>
    </Suspense>
  );
};

export const router = createBrowserRouter([
  { path: "/login", element: <Login /> },
  {
    path: "/",
    element: (
      <RequireAuth>
        <FullLayout />
      </RequireAuth>
    ),
    errorElement: <div>Page Not Found</div>,
    children: [
      {
        path: "/",
        element: (
          <SuspenseLayout>
            <Dashboard />
          </SuspenseLayout>)
      },
      {
        path: PROPERTY_ROUTE,
        element: (
          <SuspenseLayout>
            <Properties />
          </SuspenseLayout>)
      },
      {
        path: ADD_PROPERTY_ROUTE,
        element: (
          <SuspenseLayout>
            <AddProperty />
          </SuspenseLayout>)
      },
      {
        path: EDIT_PROPERTY_ROUTE + ":propertyId",
        element: (
          <SuspenseLayout>
            <EditProperty />
          </SuspenseLayout>)
      },
      {
        path: PROFILE_ROUTE,
        element: (
          <SuspenseLayout>
            <Profile />
          </SuspenseLayout>)
      },
      {
        path: LEADS_ROUTE,
        element: (
          <SuspenseLayout>
            <Leads />
          </SuspenseLayout>)
      },
      {
        path: ADD_LEAD_ROUTE,
        element: (
          <SuspenseLayout>
            <AddLead />
          </SuspenseLayout>)
      },
      {
        path: EDIT_LEAD_ROUTE + ":leadId",
        element: (
          <SuspenseLayout>
            <EditLead />
          </SuspenseLayout>)
      },
      {
        path: LEADS_ROUTE + VIEW_ROUTE,
        element: (
          <SuspenseLayout>
            <ViewLead />
          </SuspenseLayout>)
      },
      {
        path: LEADS_ROUTE + VIEW_ROUTE + APPOINTMENT_ROUTE,
        element: (
          <SuspenseLayout>
            <ViewingArranged />
          </SuspenseLayout>)
      },
      {
        path: LEADS_ROUTE + VIEW_ROUTE + VIEWING_DONE_ROUTE,
        element: (
          <SuspenseLayout>
            <ViewingDone />
          </SuspenseLayout>)
      },
      {
        path: LEADS_ROUTE + VIEW_ROUTE + DEAL_DEAL_ROUTE,
        element: (
          <SuspenseLayout>
            <Deal />
          </SuspenseLayout>)
      },
      {
        path: LEADS_ROUTE + VIEW_ROUTE + VIEW_DETAILS_ROUTE,
        element: (
          <SuspenseLayout>
            <LeadDetails />
          </SuspenseLayout>)
      },
      {
        path: HISTORY_ROUTE,
        element: (
          <SuspenseLayout>
            <History />
          </SuspenseLayout>)
      },
      {
        path: CONTACTS_ROUTE,
        element: (
          <SuspenseLayout>
            <Contacts />
          </SuspenseLayout>)
      },
      {
        path: ACCOUNT_DEALS_ROUTE,
        element: (
          <SuspenseLayout>
            <DealsPdf />
          </SuspenseLayout>)
      },
      {
        path: TRANSACTION_DETAILS_ROUTE + "/:dealId",
        element: (
          <SuspenseLayout>
            <TransactionDetails />
          </SuspenseLayout>)
      },
      {
        path: ACCOUNT_ADD_TRANSACTION_ROUTE + ":dealId"  + "/:transactionId",
        element: (
          <SuspenseLayout>
            <AddTransaction />
          </SuspenseLayout>)
      },
    ],
  },
]);
