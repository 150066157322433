import { toast } from "react-toastify";

export const errorToast = (title, config = {
  autoClose: 2000,
  closeOnClick: false,
}) => {
  return toast.error(title ?? "Unkown Error Occurred, Please Try Again", {
    position: "top-center",
    closeOnClick: config?.closeOnClick ?? true,
    theme: "light",
    autoClose: config.autoClose ?? 2000,
  });
};

export const successToast = (title) => {
  return toast.success(title ?? "Success", {
    position: "top-center",
    closeOnClick: true,
    theme: "light",
  });
};
