import TextFieldMUI from "@mui/material/TextField";
import { Controller, useFormContext } from "react-hook-form";
import PropTypes from "prop-types";
import { memo } from "react";

function TextField({
  name,
  rules,
  label,
  fullWidth = true,
  size = "small",
  type = "text",
  multiline = false,
  readOnly = false,
  charactersLimit,
  sx,
  ...props
}) {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { ref, value, onChange, ...field }, fieldState: { error } }) => {
        return (
          <TextFieldMUI
            value={value}
            onChange={onChange}
            size={size}
            fullWidth={fullWidth}
            type={type}
            label={label}
            multiline={multiline}
            error={!!error}
            helperText={error?.message}
            InputProps={{
              readOnly: readOnly,
              endAdornment: (
                <>
                  {charactersLimit && (
                    <div
                      style={{
                        color: charactersLimit - value.length < 0 ? "red" : "black",
                      }}
                    >
                      {charactersLimit - value.length}
                    </div>
                  )}
                </>
              ),
            }}
            sx={{ ...sx }}
            inputRef={ref}
            {...field}
            {...props}
          />
        );
      }}
    />
  );
}

export default memo(TextField);

TextField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};
