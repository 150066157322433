

export function removeSquareBrackets(value) {
    // Remove square brackets and quotation marks
    const cleanedValue = value.replace(/[\[\]"']/g, '');
    // Add space after each comma
    const stringWithSpaces = cleanedValue.replace(/,/g, ', ');
    return stringWithSpaces;
}



// combining data and time
export function combineDateAndTime(startDate, startTime) {
    let combinedDateTime = null;

    if (startDate && startTime) {
        const year = startDate.getFullYear();
        const month = ("0" + (startDate.getMonth() + 1)).slice(-2);
        const day = ("0" + startDate.getDate()).slice(-2);
        const hours = ("0" + startTime.getHours()).slice(-2);
        const minutes = ("0" + startTime.getMinutes()).slice(-2);
        const seconds = ("0" + startTime.getSeconds()).slice(-2);

        return combinedDateTime = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
    }
}


//
export function correctDate(startDate) {
    const leadDate = new Date(startDate);
    if (leadDate) {
        const year = leadDate.getFullYear();
        const month = ("0" + (leadDate.getMonth() + 1)).slice(-2);
        const day = ("0" + leadDate.getDate()).slice(-2);
        const formattedDate = `${month}/${day}/${year}`;
        // console.log(formattedDate);
        return formattedDate;
    }
}


