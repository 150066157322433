import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseQuery = fetchBaseQuery({
  baseUrl: "https://data.realtorscrm.com/",

  prepareHeaders: (headers, { getState }) => {
    const token = getState().auth.token;
    const agentId = getState().auth.agentId;
    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
      headers.set("userid", agentId);
    }

    return headers;
  }

  // credentials: "same-origin",
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);

  // if (result?.error) {
  //   api.dispatch(logout());
  // }
};

export const apiSlice = createApi({
  baseQuery: baseQuery,
  endpoints: () => ({}),
  tagTypes: ["Properties", "Agent", "PropertyLeads", "ExclusiveLeads"],
});
