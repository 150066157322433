import { Link } from "react-router-dom";
import { IconButton, Tooltip } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

export const CONTACT_USERS_COLUMN = [
  { accessorKey: "ownfirstname", header: "First Name", size: 20 },
  { accessorKey: "ownlastname", header: "Last Name", size: 20 },
  { accessorKey: "ownemail", header: "Email", size: 30 },
  { accessorKey: "mobileno", header: "Number", size: 30 },
  { accessorKey: "type", header: "Contact Type", size: 30 },
  {
    id: 'actions',
    header: 'Actions',
    Cell: ({ row }) => {
      const { ownemail, mobileno } = row.original;
      const whatsappUrl = `https://wa.me/${mobileno}`;
      const mailtoUrl = `mailto:${ownemail}`;
      const telUrl = `tel:${mobileno}`;

      return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}>
          <Tooltip title={mobileno ? "Send WhatsApp message" : "No mobile number available"}>
            <span>
              <IconButton component="a" href={whatsappUrl} target="_blank" aria-label="WhatsApp" disabled={!mobileno}>
                <WhatsAppIcon />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title={ownemail ? "Send Email" : "No email available"}>
            <span>
              <IconButton component="a" href={mailtoUrl} aria-label="Email" disabled={!ownemail}>
                <EmailIcon />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title={mobileno ? "Call" : "No mobile number available"}>
            <span>
              <IconButton component="a" href={telUrl} aria-label="Phone" disabled={!mobileno}>
                <PhoneIcon />
              </IconButton>
            </span>
          </Tooltip>
        </div>
      );
    },
  },
];
