import { MaterialReactTable } from "material-react-table";
import React, { useRef, useState } from "react";
import { AGENCY_PROPERTY_COLUMNS, CONTACT_USERS_COLUMN } from "./contactData";
import { MenuItem, TextField, useMediaQuery } from "@mui/material";
import { toast } from "react-toastify";
// import ShareLocation from "./ShareLocation";

export default function ContactTable({ isSuccess, isFetching, data, onClickSharePdf }) {
  const isMobileScreen = useMediaQuery("(max-width:768px)");
  const [shareLocation, setShareLocation] = useState(false)
  const [location, setLocation] = useState([])
  const tableRef = useRef();

  // const onSharePdf = (propertyIds) => {
  //   if (propertyIds.length > 0) {
  //     onClickSharePdf(propertyIds);
  //   }
  // };

  // const onShareProperties = (propertyIds) => {
  //   setShareLocation(true)
  // };

  return (
    <>
     <MaterialReactTable
    state={{ showSkeletons: isFetching }}
    columns={CONTACT_USERS_COLUMN}
    // tableInstanceRef={tableRef}
    data={data ?? []}
    // enableFixed
    // enableStickyHeader={!isMobileScreen}
    // muiTableContainerProps={{
    //   sx: { maxHeight: isMobileScreen ? "100%" : "calc(100vh - 350px)" },
    // }}
    // enableRowSelection
    // enableDensityToggle={false}
    // initialState={{ density: "compact" }}
    // getRowId={(row) => row.propertyid}
        // renderTopToolbarCustomActions={({ table }) => {
          // const propertyIds = table.getSelectedRowModel().rows.map((row) => row.id);
          // const location = table.getSelectedRowModel().rows.map((row) => setLocation(row.original.coordinates));

          // return (
          //   <TextField
          //     key={0}
          //     select
          //     variant="outlined"
          //     size="small"
          //     sx={{ minWidth: "150px" }}
          //     label="Action"
          //     value=""
          //   >
          //     <MenuItem value={1}>Option 1</MenuItem>
          //     <MenuItem value={2}>Option 2</MenuItem>
          //   </TextField>
          // );
        // }
      // }
      />
    </>
  );
}
