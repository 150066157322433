import React, { useState } from "react";
import { useLocation } from "react-router";
import { Link, NavLink } from "react-router-dom";
import {
  Box,
  Drawer,
  useMediaQuery,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  Button,
  Avatar,
  MenuItem,
  Typography,
  Divider,
} from "@mui/material";
import { SidebarWidth } from "../../../assets/global/Theme-variable";
import { LINKS, ACCOUNTS_OPTIONS } from "./data";
import { useDispatch, useSelector } from "react-redux";
import { logout, selectCurrentAgent } from "../../../redux/features/authSlice";
import { AGENT_IMAGES_URL } from "../../../constants/url";
import { PROFILE_ROUTE } from "../../../constants/routes";
import { LogoutOutlined } from "@mui/icons-material";
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";

const Sidebar = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(true);
  const currentAgent = useSelector(selectCurrentAgent);
  const { pathname } = useLocation();
  const pathDirect = pathname;
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"));
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };
  const dispatch = useDispatch();

  const handleClick = (index) => {
    if (open === index) {
      setOpen((prevopen) => !prevopen);
    } else {
      setOpen(index);
    }
  };

  const handleUserMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleUserMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    dispatch(logout());
    window.location.replace('https://realtorscrm.com')
  };

  const SidebarContent = (
    <Box sx={{ p: 3, height: "calc(100vh - 40px)", display: "flex", flexDirection: "column" }}>
      <Link to="/">
        <Box sx={{ display: "flex", alignItems: "Center", height: "80px", borderRadius: "10px", overflow: 'hidden' }}>
          <img
            src="/static/images/logo.png"
            alt="logo"
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
          />
        </Box>
      </Link>

      <Box>
        <List
          sx={{
            mt: 2,

          }}

        >
          {LINKS.map((item, index) => {
            return (
              <List component="li" disablePadding key={item.title}>
                <ListItem
                  onClick={() => handleClick(index)}
                  button
                  component={NavLink}
                  to={item.href}
                  selected={pathDirect === item.href}
                  sx={{
                    mb: 1,
                    ...(pathDirect === item.href && {
                      color: "white",
                      bgcolor: (theme) => `${theme.palette.primary.main}!important`, // Background color when active using theme
                    }),
                  }}

                >
                  <ListItemIcon
                    sx={{
                      ...(pathDirect === item.href && { color: "white" }),
                    }}
                  >
                    <item.icon width="20" height="20" />
                  </ListItemIcon>
                  <ListItemText>{item.title}</ListItemText>
                </ListItem>
              </List>
            );
          })}
        </List>


        <ListItem button onClick={toggleOpen}
          sx={{
            marginTop: "-10px",
          }}
        >
          <ListItemIcon>
            {
              isOpen ? <IoIosArrowUp width="20" height="20" /> : <IoIosArrowDown width="20" height="20" />
            }
          </ListItemIcon>
          <ListItemText>Accounts</ListItemText>
        </ListItem>
        {isOpen && (
          <List component="div" disablePadding>
            {ACCOUNTS_OPTIONS.map((option, index) => (
              <ListItem
                key={index}
                button
                component={NavLink}
                to={option.href}
                selected={pathDirect === option.href}
                sx={{
                  marginTop: '10px',
                  marginBottom: '10px',
                  ...(pathDirect === option.href && {
                    color: "white",
                    bgcolor: (theme) => `${theme.palette.primary.main}!important`,
                  }),
                }}
              >
                <ListItemIcon
                  sx={{
                    ...(pathDirect === option.href && {
                      color: "white",
                    }),
                  }}
                >
                  <option.icon />
                </ListItemIcon>
                <ListItemText>{option.title}</ListItemText>
              </ListItem>
            ))}
          </List>
        )}
      </Box>

      <Button onClick={handleUserMenuOpen} sx={{ mt: "auto" }}>
        <Box
          sx={{
            display: "flex",
            width: "100%",
            marginTop: "20px",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <Avatar
            alt={"Agency Logo"}
            src={AGENT_IMAGES_URL + currentAgent.agentimage}
            variant="rounded"
            sx={{
              width: "40px",
              height: "40px",
            }}
          />
          <Typography variant="h5" sx={{ ml: 2 }}>
            {currentAgent.agentname}
          </Typography>
        </Box>
      </Button>
      <Menu
        id="profile-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleUserMenuClose}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
        transformOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem component={Link} to={PROFILE_ROUTE} onClick={handleUserMenuClose}>
          <Avatar
            src={AGENT_IMAGES_URL + currentAgent.agentimage}
            sx={{
              width: "35px",
              height: "35px",
            }}
          />
          <Typography
            sx={{
              ml: 2,
            }}
          >
            Profile
          </Typography>
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <LogoutOutlined fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </Box>
  );
  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open={true}
        variant="persistent"
        PaperProps={{
          sx: {
            width: SidebarWidth,
          },
        }}
      >
        {SidebarContent}
      </Drawer>
    );
  }
  return (
    <Drawer
      anchor="left"
      open={props.isMobileSidebarOpen}
      onClose={props.onSidebarClose}
      PaperProps={{
        sx: {
          width: SidebarWidth,
        },
      }}
      variant="temporary"
    >
      {SidebarContent}
    </Drawer>
  );
};

export default Sidebar;
