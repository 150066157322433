import React, { useCallback, useState } from "react";
import TextFieldMUI from "@mui/material/TextField";
import { Controller, useFormContext } from "react-hook-form";
import PropTypes from "prop-types";
import { IconButton, InputAdornment } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

export default function PasswordField({
  name,
  rules,
  label,
  fullWidth = true,
  size = "small",
  sx,
  ...props
}) {
  const { control } = useFormContext();
  const [showPassword, setShowPassword] = useState(false);

  const toggleShowPassword = useCallback(() => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  }, []);

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { ref, ...field }, fieldState: { error } }) => {
        return (
          <TextFieldMUI
            size={size}
            fullWidth={fullWidth}
            type={showPassword ? "text" : "password"}
            label={label}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={toggleShowPassword}>
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={!!error}
            inputRef={ref}
            helperText={error?.message}
            sx={{ ...sx }}
            {...field}
            {...props}
          />
        );
      }}
    />
  );
}

PasswordField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};
