import Avatar from "@mui/material/Avatar";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { Button, CircularProgress, styled } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import { useState } from "react";
import { useDispatch } from "react-redux";
import TextField from "../../components/FormElements/TextField";
import PasswordField from "../../components/FormElements/PasswordField";
import { useAgentLoginMutation } from "../../redux/api/authApi";
import { setCredentials } from "../../redux/features/authSlice";

export default function Login() {
  const [error, setError] = useState("");
  const methods = useForm({
    defaultValues: { email: "", password: "", agencyid: "" },
  });
  const { handleSubmit } = methods;

  const [login, { isLoading }] = useAgentLoginMutation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onSubmit = (data) => {
    setError("");
    login(data)
      .unwrap()
      .then((res) => {
        dispatch(setCredentials({ token: res.token, agentId: res.AgentID }));
        navigate("/");
      })
      .catch((err) => {
        if (err.status === 401) return setError("Invalid Credentials");

        setError("Unkown Error Occured");
      });
  };

  return (
    <StyledContainer>
      <Avatar sx={{ m: 1, bgcolor: "#56C662" }}>
        <LockOutlinedIcon />
      </Avatar>

      <Typography variant="h2">Agent Login</Typography>

      <Typography variant="h5" color="error">
        {error}
      </Typography>
      <FormProvider {...methods}>
        <StyledForm onSubmit={handleSubmit(onSubmit)}>
          <TextField
            name="agencyid"
            rules={{ required: "Agency Id Required" }}
            label="Agency Id"
            size="large"
            type="number"
          />
          <TextField
            name="email"
            rules={{ required: "Email Address Required" }}
            label="Email Address"
            size="large"
          />
          <PasswordField
            name="password"
            rules={{ required: "password required" }}
            label="Password"
            size="large"
          />

          <Button
            type="submit"
            variant="contained"
            size="large"
            sx={{color: "white"}}
            disabled={isLoading}
            endIcon={isLoading ? <CircularProgress size={20} /> : undefined}
          >
            Sign In
          </Button>

          <StyledLinksContainer>
            <Typography component={Link} color="secondary" variant="body2">
              Forgot Password
            </Typography>
            <Typography component={Link} color="secondary" to="/signup" variant="body2">
              Don't have an account? Sign Up
            </Typography>
          </StyledLinksContainer>
        </StyledForm>
      </FormProvider>
    </StyledContainer>
  );
}

const StyledContainer = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  justifyContent: "center",
  height: "100%",
  padding: "16px",
  marginTop: "40px",
}));

const StyledForm = styled("form")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  maxWidth: "400px",
  justifyContent: "center",
  gap: "24px",
  marginTop: "24px",
}));

const StyledLinksContainer = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  gap: "16px",
  flexWrap: "wrap",
});
