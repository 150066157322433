import React, { useEffect } from "react";
import { DatePicker, LocalizationProvider, MobileTimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import TextField from "./FormElements/TextField";

const DateAndTime = ({ startDate, setStartDate, startTime, setStartTime }) => {

  useEffect(() => {
    const today = new Date();
    setStartDate(today);
    setStartTime(new Date(today.setHours(today.getHours(), today.getMinutes(), 0, 0)));
  }, [setStartDate, setStartTime]);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        value={startDate}
        format="dd/MM/yyyy"
        slotProps={{ textField: { size: 'small' } }}
        onChange={(newValue) => setStartDate(newValue)}
        renderInput={(params) => (
          <TextField {...params} label="Select Date" className="w-full" />
        )}
      />
      <MobileTimePicker
        label="Select Time"
        slotProps={{ textField: { size: 'small' } }}
        value={startTime}
        onChange={(newValue) => setStartTime(newValue)}
        renderInput={(params) => (
          <TextField {...params} size="small" label="Select Time" className="w-full" />
        )}
      />
    </LocalizationProvider>
  );
};

export default DateAndTime;
