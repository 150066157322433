export const PROPERTY_ROUTE = "/properties";
export const ADD_PROPERTY_ROUTE = "/properties/add";
export const EDIT_PROPERTY_ROUTE = "/properties/edit/";
export const PROFILE_ROUTE = "/profile";
export const LEADS_ROUTE = "/leads";
export const ADD_LEAD_ROUTE = '/leads/add';
export const EDIT_LEAD_ROUTE = '/leads/edit/';
export const VIEW_ROUTE = '/view/:id';
export const APPOINTMENT_ROUTE = '/viewingarranged/:id';
export const VIEWING_DONE_ROUTE = '/viewdone/:id';
export const DEAL_DEAL_ROUTE = '/deal/:id';
export const VIEW_DETAILS_ROUTE = '/details/:id';
export const HISTORY_ROUTE = '/:id/history/:id';
export const CONTACTS_ROUTE = '/contacts';
export const ACCOUNTS_ROUTE = '/accounts';
export const ACCOUNT_DEALS_ROUTE = ACCOUNTS_ROUTE + '/deals';
export const TRANSACTION_DETAILS_ROUTE = ACCOUNT_DEALS_ROUTE + '/transaction';
export const ACCOUNT_ADD_TRANSACTION_ROUTE = ACCOUNT_DEALS_ROUTE + '/add/';
