import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    agentId: localStorage.getItem("agentId"),
    agent: null,
    token: localStorage.getItem("token"),
  },

  reducers: {
    setCredentials: (state, action) => {
      const { token, agentId } = action.payload;
      state.token = token;
      state.agentId = agentId;
      localStorage.setItem("token", token);
      localStorage.setItem("agentId", agentId);
    },
    setAgent: (state, action) => {
      const agent = action.payload;
      state.agent = agent;
    },

    logout: (state, action) => {
      state.agentId = null;
      state.agent = null;
      state.token = null;
      localStorage.removeItem("token");
      localStorage.removeItem("agentId");
    },
  },
});

export const { setCredentials, logout, setAgent } = authSlice.actions;

export default authSlice.reducer;

export const selectCurrentAgent = (state) => state.auth.agent;

export const selectAgentId = (state) => state.auth.agentId;
