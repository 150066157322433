import { configureStore, isRejectedWithValue } from "@reduxjs/toolkit";
import { apiSlice } from "./api";
import authReducer, { logout } from "./features/authSlice";
import countryReducer from "./features/countrySlice";
import { errorToast } from "../constants/toast";



export const rtkQueryErrorLogger = (api) => (next) => (action) => {
  if (isRejectedWithValue(action)) {

    if (action?.payload?.data === 'Invalid password, email or agencyid') {
      errorToast("Invalid Credentials");

      return next(action);
    }

    if (action?.payload?.status === 401) {
      errorToast("Session Expired, Please Login Again");
      store.dispatch(logout());
    }
  }

  return next(action);
};


const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    auth: authReducer,
    country: countryReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(apiSlice.middleware, rtkQueryErrorLogger),
  devTools: false,
});

export default store;
