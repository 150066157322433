import { Grid, TextField, Typography } from "@mui/material";
import React from "react";
import { RxCross2 } from "react-icons/rx";



export const DealsDetails = ({ data, setShowDetails }) => {
    return (
        <div className="fixed z-[99999] inset-0 bg-gray-200 bg-opacity-80 flex items-center justify-center p-4">
            <div className="relative w-full h-full md:w-[85%] md:h-[95%] bg-white p-4 rounded-md overflow-auto">
                <div className="absolute top-3 z-50 right-5  cursor-pointer" onClick={() => setShowDetails(false)}>
                    <RxCross2 size={25} />
                </div>
                <div className="">
                    <h2 className="text-2xl font-bold mb-2 text-start">Deal Details</h2>
                    <Grid container spacing={2}>
                        <Grid item xs={6} md={4}>
                            <Typography variant="h6" gutterBottom>
                                Deal ID:
                            </Typography>
                            <TextField
                                fullWidth
                                value={data && data.dealId}
                                InputProps={{
                                    readOnly: true,
                                }}
                                variant="outlined"
                                size="small"
                            />
                        </Grid>
                        <Grid item xs={6} md={4}>
                            <Typography variant="h6" gutterBottom>
                                Property ID:
                            </Typography>
                            <TextField
                                fullWidth
                                value={data && data.propertyid}
                                InputProps={{
                                    readOnly: true,
                                }}
                                variant="outlined"
                                size="small"
                            />
                        </Grid>
                        <Grid item xs={6} md={4}>
                            <Typography variant="h6" gutterBottom>
                                Deal Date:
                            </Typography>
                            <TextField
                                fullWidth
                                value={data && new Date(data.dealDate).toLocaleDateString()}
                                InputProps={{
                                    readOnly: true,
                                }}
                                variant="outlined"
                                size="small"
                            />
                        </Grid>
                        <Grid item xs={6} md={4}>
                            <Typography variant="h6" gutterBottom>
                                Property Ref No:
                            </Typography>
                            <TextField
                                fullWidth
                                value={data && data.propertyrefnum}
                                InputProps={{
                                    readOnly: true,
                                }}
                                variant="outlined"
                                size="small"
                            />
                        </Grid>
                        <Grid item xs={6} md={4}>
                            <Typography variant="h6" gutterBottom>
                                Property Title:
                            </Typography>
                            <TextField
                                fullWidth
                                value={data && data.propertytitle}
                                InputProps={{
                                    readOnly: true,
                                }}
                                variant="outlined"
                                size="small"
                            />
                        </Grid>
                        <Grid item xs={6} md={4}>
                            <Typography variant="h6" gutterBottom>
                                Deal Amount:
                            </Typography>
                            <TextField
                                fullWidth
                                value={data && data.amountTotal}
                                InputProps={{
                                    readOnly: true,
                                }}
                                variant="outlined"
                                size="small"
                            />
                        </Grid>
                        <Grid item xs={6} md={4}>
                            <Typography variant="h6" gutterBottom>
                                Amount Received:
                            </Typography>
                            <TextField
                                fullWidth
                                value={data && (data.amountReceived || 0)}
                                InputProps={{
                                    readOnly: true,
                                }}
                                variant="outlined"
                                size="small"
                            />
                        </Grid>
                        <Grid item xs={6} md={4}>
                            <Typography variant="h6" gutterBottom>
                                Balance:
                            </Typography>
                            <TextField
                                fullWidth
                                value={data && (data.balance || 0)}
                                InputProps={{
                                    readOnly: true,
                                }}
                                variant="outlined"
                                size="small"
                            />
                        </Grid>
                        <Grid item xs={6} md={4}>
                            <Typography variant="h6" gutterBottom>
                                <h1 className="truncate"> Amount Received Date: </h1>
                            </Typography>
                            <TextField
                                fullWidth
                                value={data && new Date(data.amountReceivedDate).toLocaleDateString()}
                                InputProps={{
                                    readOnly: true,
                                }}
                                variant="outlined"
                                size="small"
                            />
                        </Grid>
                        <Grid item xs={6} md={4}>
                            <Typography variant="h6" gutterBottom>
                                Payment Type:
                            </Typography>
                            <TextField
                                fullWidth
                                value={data && data.paymentType}
                                InputProps={{
                                    readOnly: true,
                                }}
                                variant="outlined"
                                size="small"
                            />
                        </Grid>
                        <Grid item xs={6} md={4}>
                            <Typography variant="h6" gutterBottom>
                                Cleared Status:
                            </Typography>
                            <TextField
                                fullWidth
                                value={data && data.clearedStatus}
                                InputProps={{
                                    readOnly: true,
                                }}
                                variant="outlined"
                                size="small"
                            />
                        </Grid>
                        <Grid item xs={6} md={4} >
                            <Typography variant="h6" gutterBottom>
                                <h1 className="truncate"> Agency Commission In Percent: </h1>
                            </Typography>
                            <TextField
                                fullWidth
                                value={`${data?.AgencyCommissionInPercent ?? ''}%`}
                                InputProps={{
                                    readOnly: true,
                                }}
                                variant="outlined"
                                size="small"
                            />
                        </Grid>
                        <Grid item xs={6} md={4}>
                            <Typography variant="h6" gutterBottom>
                                <h1 className="truncate">  Agency Commission In Amount: </h1>
                            </Typography>
                            <TextField
                                fullWidth
                                value={data && data.AgencyCommissionInAmount}
                                InputProps={{
                                    readOnly: true,
                                }}
                                variant="outlined"
                                size="small"
                            />
                        </Grid>

                        {data && data.AgentsInfo && data.AgentsInfo.map(agent => (
                            <React.Fragment key={agent.AgentId}>
                                {/* <Grid item xs={12}>
                                    <Typography variant="h6" gutterBottom>
                                        Agents Information
                                    </Typography>
                                </Grid> */}
                                <Grid item xs={6} md={4}>
                                    <Typography variant="h6" gutterBottom>
                                        Agent Name:
                                    </Typography>
                                    <TextField
                                        fullWidth
                                        value={agent.AgentName}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        variant="outlined"
                                        size="small"
                                    />
                                </Grid>
                                <Grid item xs={6} md={4}>
                                    <Typography variant="h6" gutterBottom>
                                        <h1 className="truncate"> Agent Commission In Percent: </h1>
                                    </Typography>
                                    <TextField
                                        fullWidth
                                        value={`${agent.AgentCommissionInPercent}%`}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        variant="outlined"
                                        size="small"
                                    />
                                </Grid>
                                <Grid item xs={6} md={4}>
                                    <Typography variant="h6" gutterBottom>
                                        <h1 className="truncate">  Agent Commission In Amount:</h1>

                                    </Typography>
                                    <TextField
                                        fullWidth
                                        value={agent.AgentCommissionInAmount}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        variant="outlined"
                                        size="small"
                                    />
                                </Grid>
                            </React.Fragment>
                        ))}

                        {data && data.ExternalAgentsInfo && data.ExternalAgentsInfo.map(externalAgent => (
                            <React.Fragment key={externalAgent.ExAgentName}>
                                <Grid item xs={6} md={4}>
                                    <Typography variant="h6" gutterBottom>
                                        <h1 className="truncate"> External Agent Name: </h1>
                                    </Typography>
                                    <TextField
                                        fullWidth
                                        value={externalAgent.ExAgentName}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        variant="outlined"
                                        size="small"
                                    />
                                </Grid>
                                <Grid item xs={6} md={4}>
                                    <Typography variant="h6" gutterBottom>
                                        <h1 className="truncate"> External Agent Commission In Percent: </h1>
                                    </Typography>
                                    <TextField
                                        fullWidth
                                        value={`${externalAgent.ExCommissionInPercent}%`}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        variant="outlined"
                                        size="small"
                                    />
                                </Grid>
                                <Grid item xs={6} md={4}>
                                    <Typography variant="h6" gutterBottom>
                                        <h1 className="truncate">External Agent Commission In Amount:  </h1>
                                    </Typography>
                                    <TextField
                                        fullWidth
                                        value={externalAgent.ExCommissionInAmount}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        variant="outlined"
                                        size="small"
                                    />
                                </Grid>
                            </React.Fragment>
                        ))}

                    </Grid>
                </div>
            </div>
        </div>
    );
};
