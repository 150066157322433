import { toast } from "react-toastify";
import { apiSlice } from ".";
const token = localStorage.getItem("token");

const leadsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getLeads: builder.query({
      query: (agcid) =>
        `/LeadTables/GetLeadsGroupedByUserIdAgentId2?agcid=${agcid}`,
    }),

    getFromAppointmentPropertiesId: builder.query({
      query: ({ token, leadId, agtid }) => ({
        url: `/LeadHistoryTables/GetLeadHistoryTable/${leadId}`,
        method: "GET",
        headers: {
          token: `Bearer ${token}`,
          agtid: agtid,
        },
      }),
    }),
    getLeadsHistory: builder.query({
      query: ({ token, leadId, userid }) => ({
        url: `/LeadHistoryTables/GetLeadHistoryTable/${leadId}`,
        method: "GET",
        headers: {
          token: `Bearer ${token}`,
          userid: userid,
        },
      }),
    }),

    getViewedProperties: builder.query({
      query: (userPropIds) => `PropertyFilter/Gettt?userPropIds=${userPropIds}`,
    }),

    getPropertyDetailsWithImages: builder.query({
      query: (propid) => `/PropertyFilter/Gettt?propid=${propid}`,
    }),

    getAgents: builder.query({
      query: (agencyId) => `/Agent/GetAgentbyAgencyID/${agencyId}`,
      providesTags: ["Agents"],
    }),
  

    getSuggestedProperties: builder.query({
      query: ({
        agcid,
        agtid,
        MinBed,
        MaxBed,
        MinBath,
        MaxBath,
        MinPropSize,
        MaxPropSize,
      }) =>
        `/PropertyFilter/Gettt?agcid=${agcid}&agtid=${agtid}&MinBed=${MinBed}&MaxBed=${MaxBed}&MinBath=${MinBath}&MaxBath=${MaxBath}&MinPropSize=${MinPropSize}&MaxPropSize=${MaxPropSize}`,
    }),

    postLeadStatus: builder.mutation({
      query: (data) => ({
        url: "/LeadHistoryTables/PostLeadHistoryTable",
        method: "POST",
        body: data,
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        toast.promise(queryFulfilled, {
          pending: "Updating Lead Status",
          success: "Lead status has been updated",
          error: "Error updating Lead status",
        });
      },
    }),
    postDealStatus: builder.mutation({
      query: (data) => ({
        url: "/dealTransactionTables/BulkPostRequest",
        method: "POST",
        body: data,
        headers: {
          Authorization: `Bearer ${token}`,
          userId: data.userid,
        },
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        toast.promise(queryFulfilled, {
          pending: "Updating Lead Status",
          success: "Lead status has been updated",
          error: "Error updating Lead status",
        });
      },
    }),

    postLead: builder.mutation({
      query: (data) => ({
        url: "/LeadTables/PostleadAndHistory",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["PropertyLeads", "ExclusiveLeads"],
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        toast.promise(queryFulfilled, {
          pending: "Adding Lead",
          success: "Lead Added",
          error: (error) => `Error Adding Lead: ${error}`,
        });
      },
    }),

    getLeadById: builder.query({
      query: (id) => `/LeadTables/GetLeadTable/${id}`,
    }),

    getLeadByPhoneNumber: builder.query({
      query: (id) => `/UserProfiles/UserChecking?phone=${id}`,
    }),

    editLead: builder.mutation({
      query: (data) => ({
        url: `/LeadTables/PutLeadTable/${data.leadid}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["PropertyLeads", "ExclusiveLeads"],
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        toast.promise(queryFulfilled, {
          pending: "Editing Lead",
          success: "Lead Edited",
          error: "Error Editing Lead",
        });
      },
    }),
    updateInstallment: builder.mutation({
      query: (data) => ({
        url: `/dealTransactionTables/PutdealTransactionTable/${data.amounttransactionID}`,
        method: "PUT",
        body: data,
        headers: {
          authorization: `Bearer ${token}`,
          userid: data.agentId,
        },
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        toast.promise(queryFulfilled, {
          pending: "Updating installment Plan",
          success: "Installment Plan updated",
          error: "Error Updating Installment Plan",
        });
      },
    }),

      
      Status: builder.query({
        query: () => `/StatusTables/GetStatusTables`,
      }),
      leadMedium: builder.query({
        query: () => `/MediumTables/GetMediumTables`,
      }),
      leadSource: builder.query({
        query: () => `/SourceTables/GetSourceTables`,
      }),
      
      leadType: builder.query({
        query: () => `/LeadTypeTables/GetLeadTypeTables`,
      }),
      leadPriority: builder.query({
        query: () => `/PriorityTables/GetPriorityTables`,
      }),
      dealInstallments: builder.query({
        query: () => `/InstallmentPlanTables/GetInstallmentPlanTables`,
      }),
      
      }),
      });
      
      export const {
        useGetLeadsQuery,
        useGetLeadsHistoryQuery,
        usePostDealStatusMutation,
        useGetViewedPropertiesQuery,
        useGetAgentsQuery,
        useGetFromAppointmentPropertiesIdQuery,
        useGetPropertyDetailsWithImagesQuery,
        useGetSuggestedPropertiesQuery,
        usePostLeadStatusMutation,
        usePostLeadMutation,
        useGetLeadByIdQuery,
        useEditLeadMutation,
        useUpdateInstallmentMutation,
        useGetLeadByPhoneNumberQuery,
        useStatusQuery, 
        useLeadMediumQuery,
        useLeadSourceQuery,
        useLeadTypeQuery,
        useLeadPriorityQuery,
        useDealInstallmentsQuery
      } = leadsApi;
      