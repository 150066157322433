export const CASH_TYPE = 0;
export const CHEQUE_TYPE = 1;
export const ONLNE_TRANSFER_TYPE = 2;
export const AUTO_DEBIT_TYPE = 3;

export const TRANSACTION_TYPES = [
    { id: CASH_TYPE, name: "Cash" },
    { id: CHEQUE_TYPE, name: "Cheque" },
    { id: ONLNE_TRANSFER_TYPE, name: "Online Transfer" },
    { id: AUTO_DEBIT_TYPE, name: "Auto Debit" },
  ];
 